<template>
  <el-dialog
    append-to-body
    title="历史使用频次"
    :visible.sync="open"
    width="480px"
    @close="close"
  >
    <el-table
      v-loading="listLoading"
      border
      :cell-style="{ padding: '2px' }"
      :data="list"
      default-expand-all
      element-loading-text="拼命加载中"
      :height="height"
      :row-style="{ height: '30px' }"
    >
      <el-table-column
        align="center"
        label="序号"
        show-overflow-tooltip
        type="index"
        width="80"
      />
      <el-table-column
        align="center"
        label="历史使用客户"
        prop="customerCode"
        show-overflow-tooltip
        width="160"
      />

      <el-table-column
        align="center"
        label="累计使用次数"
        prop="userTime"
        show-overflow-tooltip
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    <template #footer>
      <el-button type="primary" @click="close">关闭</el-button>
    </template>
  </el-dialog>
</template>
<script>
  import { customerMappingFrequencyListApi } from '@/api/cmsApi'
  export default {
    props: {
      open: {
        type: Boolean,
      },
      id: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dialogFormVisible: false,
        list: [],
        height: 300,
        listLoading: false,
      }
    },
    watch: {
      open(openValue) {
        if (openValue) {
          this.getList()
        }
      },
    },
    methods: {
      async getList() {
        const { data } = await customerMappingFrequencyListApi({ id: this.id })
        this.list = data
      },
      close() {
        this.$emit('update:open', false)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
