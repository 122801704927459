var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "660px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "editForm",
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "vab-query-form-top-panel",
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        label: "客户中文品名:",
                        prop: "cusProductCnName"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.cusProductCnName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "cusProductCnName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.cusProductCnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        label: "客户英文品名:",
                        prop: "cusProductEnName"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.cusProductEnName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "cusProductEnName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.cusProductEnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "申报中文品名:", prop: "reportCnName" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.reportCnName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "reportCnName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.reportCnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "申报英文品名:", prop: "reportEnName" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.reportEnName,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "reportEnName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.reportEnName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "中国海关编码:", prop: "hsCode" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.hsCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "hsCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.hsCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "中国申报要素:", prop: "reportFactor" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.reportFactor,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "reportFactor",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.reportFactor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "中文材质:", prop: "materialCn" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.materialCn,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "materialCn",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.materialCn"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "英文材质:", prop: "materialEn" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.materialEn,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "materialEn",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.materialEn"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "用途:", prop: "useTo" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.useTo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "useTo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.useTo"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "商品描述:", prop: "description" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "description",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "大类:", prop: "category" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.category,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "category",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.category"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "章节:", prop: "chapter" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.chapter,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "chapter",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.chapter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "商品名称:", prop: "items" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.items,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "items",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.items"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "监管条件:", prop: "regulatoryCondition" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.regulatoryCondition,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "regulatoryCondition",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.regulatoryCondition"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "检验检疫:", prop: "inspection" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "500",
                          placeholder: "请输入",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.form.inspection,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "inspection",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.inspection"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }