var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "conten" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "comprehensive-table-container" },
      [
        _c(
          "vab-query-form",
          [
            _c(
              "vab-query-form-left-panel",
              { staticStyle: { float: "left", "margin-top": "20px" } },
              [
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permissions",
                        rawName: "v-permissions",
                        value: ["admin"],
                        expression: "['admin']"
                      }
                    ],
                    attrs: { type: "primary" },
                    on: { click: _vm.addOrEditDetails }
                  },
                  [_vm._v(" 新增 ")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "permissions",
                        rawName: "v-permissions",
                        value: ["admin"],
                        expression: "['admin']"
                      }
                    ],
                    attrs: { type: "warning" },
                    on: { click: _vm.deleteData }
                  },
                  [_vm._v(" 删除 ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.listLoading,
                expression: "listLoading"
              }
            ],
            ref: "tableSort",
            attrs: {
              border: "",
              "cell-style": { padding: "2px" },
              data: _vm.list,
              "default-expand-all": "",
              "element-loading-text": "拼命加载中",
              height: _vm.height,
              "row-style": { height: "30px" }
            },
            on: { "selection-change": _vm.setSelectRows },
            scopedSlots: _vm._u([
              {
                key: "empty",
                fn: function() {
                  return [
                    _c("el-image", {
                      staticClass: "vab-data-empty",
                      attrs: {
                        src: require("@/assets/empty_images/data_empty.png")
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _vm._v(" > "),
            _c("el-table-column", {
              attrs: {
                align: "center",
                "show-overflow-tooltip": "",
                type: "selection",
                width: "55"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "客户中文品名",
                prop: "cusProductCnName",
                "show-overflow-tooltip": "",
                width: "180"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "客户英文品名",
                prop: "cusProductEnName",
                "show-overflow-tooltip": "",
                width: "180"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "申报中文品名",
                prop: "reportCnName",
                "show-overflow-tooltip": "",
                width: "180"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "申报英文品名",
                prop: "reportEnName",
                "show-overflow-tooltip": "",
                width: "180"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "中国海关编码",
                prop: "hsCode",
                "show-overflow-tooltip": "",
                width: "140"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "中国申报要素",
                prop: "reportFactor",
                "show-overflow-tooltip": "",
                width: "180"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "中文材质",
                prop: "materialCn",
                "show-overflow-tooltip": "",
                width: "140"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "英文材质",
                prop: "materialEn",
                "show-overflow-tooltip": "",
                width: "180"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "用途",
                prop: "useTo",
                "show-overflow-tooltip": "",
                width: "180"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "进口国家",
                prop: "importCountry",
                "show-overflow-tooltip": "",
                width: "120"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "进口海关编码",
                prop: "importHsCode",
                "show-overflow-tooltip": "",
                width: "120"
              }
            }),
            _vm.$roles.roles[0] == "admin"
              ? _c("el-table-column", {
                  attrs: {
                    align: "left",
                    label: "关税税率",
                    prop: "rateOfDuty",
                    "show-overflow-tooltip": "",
                    width: "120"
                  }
                })
              : _vm._e(),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "认证要求",
                prop: "certification",
                "show-overflow-tooltip": "",
                width: "80"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "风险评估",
                prop: "riskTypeName",
                "show-overflow-tooltip": "",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "是否可承接",
                prop: "acceptabilityName",
                "show-overflow-tooltip": "",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "包税附加费",
                prop: "surcharge",
                "show-overflow-tooltip": "",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "创建人",
                prop: "createBy",
                "show-overflow-tooltip": "",
                width: "100"
              }
            }),
            _c("el-table-column", {
              attrs: {
                align: "left",
                label: "创建时间",
                prop: "createTime",
                "show-overflow-tooltip": "",
                width: "160"
              }
            }),
            _vm.$roles.roles[0] == "admin"
              ? _c("el-table-column", {
                  attrs: {
                    align: "center",
                    fixed: "right",
                    label: "操作",
                    "show-overflow-tooltip": "",
                    width: "130"
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.addOrEditDetails(row)
                                  }
                                }
                              },
                              [_vm._v(" 编辑 ")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3774863062
                  )
                })
              : _vm._e()
          ],
          1
        ),
        _c("DetailsAdd", {
          ref: "addOrEditDetails",
          staticStyle: { "z-index": "99" },
          attrs: { "file-id": _vm.fileId, modulelist: _vm.detailsList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("h3", [_vm._v("客户与关务映射明细")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }